// ScrollItems.js
import React from 'react';
import '../global.css';
import CommomScroll from './CommomScroll'
import Users from './Users';
import Research from '../research/research';

const ScrollItems = () => {
  const blogUrls = [
    "https://pubmed.ncbi.nlm.nih.gov/15564054/",
    "https://www.tandfonline.com/doi/abs/10.1080/00461520.2010.515933",
    "https://bera-journals.onlinelibrary.wiley.com/doi/abs/10.1080/01411926.2010.501096"
  ];

  const researchUrls = [
    "https://pubmed.ncbi.nlm.nih.gov/15564054/",
    "https://www.tandfonline.com/doi/abs/10.1080/00461520.2010.515933",
    "https://bera-journals.onlinelibrary.wiley.com/doi/abs/10.1080/01411926.2010.501096"
  ];
  return (
    <div className='container-fluid bg-dark p-0'>
      <div className="scroll-items bg-dark vp-container pt-4">
        {/* Your scroll items/content go here */}
        <div class="row  gap-5 mt-5">
          <div class="col-md">
            <span class='custom-large-heading light-col'>Features</span>
          </div>
          <div class="col-md">
            <span class='montser-sml-LightItalic light-col ft-section-heading'>Unlock your potential and propel your career to new heights with our comprehensive suite of career development services. From Talent Optimization and Skill Enhancement to Adaptive Learning and Corporate Training, we're here to support your journey towards professional excellence.</span>
          </div>
        </div>

        <div className="row text-yellow pt-2 gx-5 img-spacing">
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/professionaldevelopment " className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 text-yellow scroll-text montser-mdm-2 common-textshadow ">Professional Development</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Personalized, adaptive, engaging</span>
                </div>
                <img src='images/Professional Development Square.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"  ></img>

              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/skillenhancement" className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 text-yellow scroll-text scroll-text common-textshadow  montser-mdm-2 ">Skill Enhancement</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Sharpen focus, Supercharge memory</span>
                </div>
                <img src='images/Skill Enhancement Square.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/corporatetraining" className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 text-yellow scroll-text common-textshadow montser-mdm-2  ">Corporate Training</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Education for all abilities</span>
                </div>
                <img src='images/Corporate Training Square.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/adaptivelearning" className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 text-yellow scroll-text common-textshadow  montser-mdm-2">Adaptive Learning</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Embrace cultural insights</span>
                </div>
                <img src='images/Adaptive Learning Square.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/careernavigation" className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 scroll-text text-yellow common-textshadow  montser-mdm-2 ">Career Navigation</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Grow Your brain</span>
                </div>
                <img src='images/Career Navigation Square.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/talentoptimization" className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 scroll-text text-yellow common-textshadow  montser-mdm-2 ">Talent Optimization</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Streamlined educational administration</span>
                </div>
                <img src='images/Talent Optimization Square.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              </a>
            </div>
          </div>
          <div className="col-12">
            <hr className='mt-8rem'></hr>
          </div>
        </div>
      </div>
      <CommomScroll title="Blog" urls={blogUrls} />
      <Research />
      <Users />
    </div>

  );
};

export default ScrollItems;