
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MyPopupbutton from '../react-calendly/MyPopupbutton';

const EnhancedAi = () => {
  useEffect(() => {
    const elements = document.getElementsByClassName("text-yellow");

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add(".text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("enhancedAiSectionActive");
  }, []);

  return (
    // <>

    <div className=''>
      {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
      {/* <> */}
      <Navbar />
      <div style={{ height: '100%', paddingTop: '100px' }} className='bg-body-enhanced '>
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section>
            <h4 className='mb-3 custom-large-heading section-margin'>Professional Development</h4>
            <hr className='mt-5'></hr>
          </section>
          
          <section className='vp-section'>
              <div className='section-contaner'>

                <div className='row'>
                  <div className='col-12 col-md-6 flex-column align-items-start inner-container gap-5 '>

                    <div className='mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg '>
                          <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                        <span className='montser-mdm-2 common-textshadow ft-2-5vh' >Career Growth Pathways </span>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Offers a comprehensive suite of courses and workshops focused on advancing professional skills and leadership capabilities. Professional Development is designed to empower individuals with the knowledge and tools necessary for career advancement, preparing them for leadership roles and new opportunities in a dynamic job market.</p>
                        {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Adapts to progress and needs of individual learners.</p>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Tailors educational content for maximum efficacy.</p> */}


                      </div>
                      <div><i className='demo-icon icon-checkiconsolid' /></div>
                    </div>
                  </div>

                  <div className='mb-0 mb-0 w-100'>
                    <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                      <div className='job-width lh-lg'>
                        <h6 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h6>
                        <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Skills Enhancement and Mastery</span>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Emphasizes the continuous enhancement of both hard and soft skills, ensuring professionals are well-equipped to meet the challenges of their current and future roles. This feature supports lifelong learning and mastery of competencies critical for professional success.</p>
                        {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Engages students more effectively with adaptive techniques.</p>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Enhances overall learning efficiency.</p> */}

                      </div>
                      <div><i className='demo-icon icon-checkiconsolid' /></div>
                    </div>
                  </div>

                  <div className='mb-0 mb-0 w-100'>
                    <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                      <div className='job-width lh-lg'>
                        <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                        <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Industry-Relevant Learning</span>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Curates content that is directly aligned with the latest industry trends and demands, providing learners with insights and skills that are immediately applicable in their workplaces. Professional Development bridges the gap between traditional education and the evolving needs of the global job market.</p>
                        {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Provides real-time, data-driven learning adjustments.</p>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Ensures challenges are matched to learner’s current abilities.</p> */}
                      </div>
                      <div><i className='demo-icon icon-checkiconsolid' /></div>
                    </div>
                  </div>
                </div>
                <div className='col-md-5 col-12 mt-3 mt-md-0 d-flex align-items-center'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5 image-container'>
                      <img src='/images/Enhanced Learning AI.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section className='vp-section'>
            <div className='justify-content-center'>
              <div className=' d-flex flex-column align-items-start text-left gap-5'>
                <p className='montser-mdm-2 vp-container common-textshadow mb-3 text-center lh-lg ft-3vh'>
                Accelerate Your Career with Targeted Development! 
                </p>
                <div className="width-100 ">
                  <img src='/images/Professional Development Wide.png' alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
                </div>
              </div>
            </div>
          </section>

          
          <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">

          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-7 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Our cutting-edge artificial intelligence technology focuses on three key areas to deliver a truly personalized and engaging learning experience.
                </p>
                  </div>
                </div>
              </div>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Brain Training 3.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Unlock new opportunities for career advancement with specialized training designed for professional growth.                </p>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Enhance your skill set with a focus on both the technical and soft skills needed to excel in today's competitive environment.
                  </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Brain Training 2.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
           
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Brain Training 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Stay ahead of industry changes with courses that reflect the latest trends and best practices in your field.              </p>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Benefit from a personalized learning experience that aligns with your professional goals and learning style. 
                  </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/school image 4.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/school image 2.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Connect with a community of professionals and experts dedicated to continuous improvement and innovation.              </p>
                    </div>
                </div>
              </div>
            </div>
          </section>


          
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Click 'Meet Us' to explore how Career Prep School’s Professional Development can support your journey towards achieving your career aspirations and becoming a leader in your industry.
                      </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/learning image 4.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
            <div className="row ">
                    <div className='col-sm-5'>
                      < div className='d-flex align-items-center mt-4 mt-sm-0 justify-content-center footer'>
                          <MyPopupbutton/>
                        </div>
                    </div>
                    <div className='col-sm-7'></div>
                  </div>
          </section>
        </div>
        <Footer />
      </div>
      {/* </> */}
    </div>
    // </div>
    // </>


  );
};

export default EnhancedAi;

