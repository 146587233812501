// Students.js
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import './aboutus.css';
import VideoBackground from '../components/VideoBackground';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Aboutus = () => {
    const dynamicVideoLink = "videos/Sequence 01_2.mp4";
    const pageName = "aboutPage"
    const buttonText = "Home"

    useEffect(() => {
        const elements = document.getElementsByClassName("text-yellow");

        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add(".text-dark1");
        }
        const section = document.getElementsByClassName("mainSectionDiv")[0];
        section.classList.add("aboutSectionActive");
    }, []);

    return (
        // <>

        <div className=''>
            {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
            {/* <> */}
            <Navbar />
            <VideoBackground
                videoLink={dynamicVideoLink}
                buttonText={buttonText}
                pageName={pageName}
            />
            <div style={{ height: '100%', paddingTop: '20px' }} className='bg-body-about'>
                <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
                    <div className='content-margin '>
                       
                       
                       
                        <div className='mt-2 pt-3'>
                        <heding className='montser-lrg-bold align-items-center py-4'>Bridging Aspirations and Realities in the Professional World: </heding>

                        <p className='align-items-center monster-lrg-normal p-gap ' style={{ paddingTop: '1.5rem' }}>
                        Welcome to Career Prep School, where individual career ambitions meet corporate excellence. Our platform is a beacon for those seeking to excel in their careers, as well as for corporations striving to enhance their workforce capabilities and streamline the hiring process. Utilizing the cutting-edge Virtu Prep technology, we offer a unique blend of work skills training, employment readiness, professional development, and corporate training solutions. Career Prep School stands at the intersection of personal growth and organizational development, ensuring both individuals and companies are equipped to thrive in the dynamic professional landscape of today and tomorrow.
                        </p>
                       
           
                        <heding className='montser-lrg-bold align-items-center py-4'>Our Vision: Elevating Careers, Empowering Businesses</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Our mission is to redefine career preparation and professional development by offering personalized, data-driven learning experiences. For individuals, this means access to the tools and knowledge needed to navigate the job market successfully, achieve career advancements, and transition into new roles.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        For corporations, we provide robust solutions for pre-screening potential hires and fostering ongoing professional development within their teams.
                        </p>
                        

                        <heding className='montser-lrg-bold align-items-center py-4'> Comprehensive Solutions for Every Step of the Professional Journey:</heding>

                        <p className='align-items-center monster-lrg-normal pt-1-5rem'>
                        For Individuals: From foundational work skills and employment readiness to advanced professional development courses, our platform supports your career journey at every stage. Tailored learning paths, based on AI-driven insights, adapt to your evolving goals and learning preferences. 
                        </p>
                        <p className='align-items-center monster-lrg-normal pt-1-5rem'>
                        For Corporations: Our platform serves as a strategic partner in identifying top talent and enhancing internal capabilities. Through customized pre-screening tools and corporate training programs, we help businesses optimize their hiring processes and invest in their employees' growth.
                        </p> 
                        {/* <p className='align-items-center monster-lrg-normal py-4'>
                        We also recognize the vital role of parents and guardians in the educational process. Our platform includes tools for parental involvement, enabling them to stay connected and actively participate in their child's education.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap'>
                        For educators and administrators, this wealth of data opens up new possibilities for tailored teaching strategies, allowing them to become architects of personalized learning experiences. Virtu Prep also plays an active role in professional development, offering educators and administrators workshops, webinars, and training to keep them abreast of the latest in teaching strategies and technologies.
                        </p> */}
                       


                        <heding className='montser-lrg-bold align-items-center py-4'>A Dual Approach: Personalized Learning and Strategic Corporate Solutions:</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Career Prep School harnesses the power of advanced technology to create a learning environment that is both engaging for individuals and strategic for corporations. Our platform:
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        Adapts to the unique learning styles and professional aspirations of each user.Offers corporations tools for efficient pre-screening and targeted employee development.
                        </p>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Provides access to a vast library of courses and modules, designed to meet the needs of today’s diverse job market and organizational challenges.Forge Your Path with Career Prep School

                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        Whether you’re seeking to elevate your career or looking to enhance your corporate team's skills, Career Prep School is your partner in professional excellence. We are a community of forward-thinkers, driven by the commitment to foster success in the workforce.
                        </p>

                        {/* <heding className='montser-lrg-bold align-items-center py-4'>Beyond Academics: Fostering Life Skills and Personal Growth: </heding> */}
                        {/* <p className='align-items-center monster-lrg-normal py-4'>
                        Provides access to a vast library of courses and modules, designed to meet the needs of today’s diverse job market and organizational challenges.Forge Your Path with Career Prep School

                        </p> */}
                        {/* <p className='align-items-center monster-lrg-normal p-gap '>
                        Whether you’re seeking to elevate your career or looking to enhance your corporate team's skills, Career Prep School is your partner in professional excellence. We are a community of forward-thinkers, driven by the commitment to foster success in the workforce.
                        </p> */}
                       

                        <heding className='montser-lrg-bold align-items-center py-4'>Join Career Prep School:</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Join us to explore how our targeted, technology-driven approach to career and professional education can unlock new possibilities for individuals and corporations alike. Welcome to a new era of professional development and corporate training. Welcome to Career Prep School.
                        </p>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Click 'Meet Us' to learn more about our innovative solutions for career advancement and corporate success.
                        </p>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
            {/* </> */}
        </div>
        // </div>
        // </>


    );
};

export default Aboutus;

