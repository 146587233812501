
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MyPopupbutton from '../react-calendly/MyPopupbutton';

const Administrators = () => {
  useEffect(() => {
    const elements = document.getElementsByClassName("text-yellow");

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add(".text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("administratorsSectionActive");
  }, []);

  return (
    // <>

    <div className=''>
      {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
      {/* <> */}
      <Navbar />
      <div style={{ height: '100%', paddingTop: '100px' }} className='body-color-ad'>
        <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section>
            <h4 className='mb-3 custom-large-heading section-margin'>Administrators</h4>
            <hr className='mt-5'></hr>
          </section>
          <section className='vp-section'>
            <div className='row'>
              <div className='col-sm-6 flex-column align-items-start inner-container gap-5'>

                <div className='mb-0 w-100'>
                  <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                    <div className='job-width lh-lg '>
                      <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>JOB:</h5>
                      <span className='montser-mdm-2 common-textshadow ft-2-5vh' >IMPROVE THE HIRING PROCESS </span>
                      <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>by elevating your classrooms and increasing the focus and attention of your students through rewards</p>
                    </div>
                    <div><i className='demo-icon icon-checkiconsolid' /></div>
                  </div>
                </div>


                <div className='mb-0 w-100'>
                  <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                    <div className='job-width lh-lg '>
                      <h6 className='ommon-textshadow  montser-mdm-2 ft-4vh '>JOB:</h6>
                      <span className='montser-mdm-2 common-textshadow ft-2-5vh'>ELEVATE STANDARDIZED SCORES</span>
                      <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>by using tools to identify what’s working and why
                      </p>
                    </div>
                    <div><i className='demo-icon icon-checkiconsolid' /></div>
                  </div>
                </div>


                <div className='mb-0 w-100'>
                  <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                    <div className='job-width lh-lg '>
                      <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>JOB:</h5>
                      <span className='montser-mdm-2 common-textshadow ft-2-5vh'  >MAXIMIZE TIME AND OUTCOMES</span>
                      <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>by eliminating waste and surprises. Know the strengths and weaknesses of your classroom in real-time and intervene to help your teachers shine</p>
                    </div>
                    <div><i className='demo-icon icon-checkiconsolid' /></div>
                  </div>
                </div>

              </div>
              <div className='col-md-5 col-12 mt-3 mt-md-0 d-flex align-items-center'>
                    <div className='w-100 maxw-450 ms-auto d-flex mt-5 image-container'>
                  <img src='images/Administrator_Image_1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img me-auto" />
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className='vp-section'>
          <div className='justify-content-center'>
            <div className=' d-flex flex-column align-items-start text-left gap-5'>
              <p className='montser-mdm-2 vp-container common-textshadow mb-3 text-center lh-lg ft-3vh'>
                Drive the evolution of your school's learning environment!
              </p>
              <div className="width-100 ">
                <img src='images/Widescreen administration_1.png' alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
              </div>
            </div>
          </div>
        </section>



        <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                      Our cutting-edge, neuroscience-backed platform addresses key challenges faced by administrators, such as managing diverse learning needs, streamlining assessments, and providing effective teacher training and professional development.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                  <img src='images/Culture Focused 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img  ms-auto" />
                </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                  <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                    Virtu Prep's AI-driven personalization delivers tailored content for students and empowers teachers with customized learning materials, saving time and boosting academic success.
                  </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-7'>
                <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                  <img src='images/Brain Training 3.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                  <div className="mt-3 mt-sm-0">
                    <p className="montser-sml-LightItalic lh-lg ft-3vh">
                      Virtu Prep's AI-driven personalization delivers tailored content for students and empowers teachers with customized learning materials, saving time and boosting academic success.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>



          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                      Enhance your teachers' skills with targeted professional development and elevate your institution's performance through data-driven decision-making.
                    </p>
                  </div>
                </div>
                <div className='d-flex align-items-center pb-3 justify-content-center'>
                  <button type="button" className="outline-lightgreen py-0 montser-mdm-2 rounded-top rounded-0 btn btn-outline-light d-none d-none">
                    <span className='text-yellow'>Meet us</span>
                  </button>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                  <img src='images/Brain Training 2.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img  ms-auto" />
                </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                  <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Embrace Virtu Prep and lead your school to new heights of excellence and innovation.
                  </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-7'>
                <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                  <img src='images/Neuro Biofeedback 3.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                  <div className="mt-3 mt-sm-0">
                    <p className="montser-sml-LightItalic lh-lg ft-3vh">
                    Embrace Virtu Prep and lead your school to new heights of excellence and innovation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>Hit the Meet Us button to schedule a demonstration with one of our dedicated Team Members.</p>
                  </div>
                </div>
                <div className='d-flex pb-3 align-items-center justify-content-center'>
                  <button type="button" className="outline-lightgreen py-0 montser-mdm-2 rounded-top rounded-0 btn btn-outline-light d-none d-none">
                    <span className='text-yellow '>Meet us</span>
                  </button>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                  <img src='images/school image 1.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                </div>
              </div>
              <div className="row ">
                    <div className='col-sm-5'>
                      < div className='d-flex align-items-center mt-4 mt-sm-0 justify-content-center footer'>
                      <MyPopupbutton/>
                        </div>
                    </div>
                    <div className='col-sm-7'></div>
                  </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
      {/* </> */}
    </div>
    // </div>
    // </>

  );
};

export default Administrators;

